import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import * as api from '@fwk-client/utils/api';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '@root/src/client/store/application';

interface CmsAdminInput {
}

export function useCmsAdmin(props:CmsAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();

  const updateInputWithTargetDB = (input:any) => {
    // We check if we have a target DB for the content
    var targetDB = store.getters['application/'+applicationTypes.getters.GET_ADMIN_TARGET_DB];
    if(targetDB && !input.targetDB) {
      input.targetDB = targetDB;
    }
  }

  const callCmsAdmin = async (path:string, input?:any, formData?:FormData, apiOptions?:api.ApiOptions) => {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: app,
      ...apiOptions
    }
    if(!input) { input = {}; }
    updateInputWithTargetDB(input);
    if(formData) {
      return api.postAPIFormData('/api/admin/cms' + path, formData, options, input)
    }
    return api.postAPI('/api/admin/cms' + path, input, options);
  }

  return {
    callCmsAdmin
  }
  
}