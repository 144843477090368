import { Ref, watch, computed, ref, reactive, defineProps, PropType, provide } from "@fwk-node-modules/vue";
import * as api from '@fwk-client/utils/api';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { languagesTypes, metaTypes} from '@fwk-client/store/types';
import { RouteMeta } from '@fwk-client/types/meta';
import { utils } from '@fwk-client/router/utils';
import { getPath } from '@igotweb-node-api-utils/misc';


export function useGenericPage(props:any, {emit}:any) { 
  const app = getApp();
  const store = useStore();

  const currentLanguageCode = computed(() => {
    return store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  })

  const routeMeta = computed(():RouteMeta =>  {
    return store.getters['meta/' + metaTypes.getters.GET_ROUTE_METAS];
  })

  const currentBodyClasses = computed(() => {
    return store.getters['meta/' + metaTypes.getters.GET_BODY_CLASSES];
  })

  const currentStyle = computed(() => {
    return store.getters['meta/' + metaTypes.getters.GET_STYLE];
  })

  const hreflangLinks = computed(() => {
    const baseURL = (store.state.server && store.state.server.baseURL) ? store.state.server.baseURL : null;
    const route = store.state.route;
    const supportedLanguages:string[] = store.getters['languages/' + languagesTypes.getters.GET_AVAILABLE_LANGUAGES]
    if(baseURL) {
      var links = [];
      // We generate the link for each language we have.
      for(var languageCode of supportedLanguages) {
        var link = {
          vmid: 'hreflang-'+languageCode,
          rel: 'alternate',
          hreflang: languageCode,
          href: baseURL + utils.getRoutePathWithLanguage(route, languageCode)
        }
        links.push(link);
      }

      // We generate the default link
      var link = {
        vmid: 'hreflang-x-default',
        rel: 'alternate',
        hreflang: 'x-default',
        href: baseURL + utils.getRoutePathWithLanguage(route)
      }
      links.push(link);
      
      return links;
    }
    return [];
  })

  const faviconMetas = computed(() => {
    return store.getters['meta/' + metaTypes.getters.GET_FAVICON_METAS];
  })

  const stylesMeta = computed(() => {
    return store.getters['meta/' + metaTypes.getters.GET_STYLES_METAS];
  })

  const appMeta = computed(() => {
    return store.getters['meta/' + metaTypes.getters.GET_APPLICATION_METAS];
  })

  const configMeta = computed(() => {
    // We get the meta exposed by the fwk config
    var metas = getPath(store.state, "server.configuration.fwk.metas");
    var configMeta:any =  {
      meta:[],
      links:[],
      htmlAttrs:{}
    }
    if(metas && metas.meta) { configMeta.meta.push(...metas.meta); }
    if(metas && metas.links) { configMeta.links.push(...metas.links); }
    if(metas && metas.htmlAttrs) { configMeta.htmlAttrs = {
        ...configMeta.htmlAttrs,
        ...metas.htmlAttrs
      }; 
    }
    return configMeta;
  })

  const metaInfo = computed(() => {
    const bodyClasses = currentBodyClasses.value;
    const faviconMeta = faviconMetas.value.meta;
    const faviconLinks = faviconMetas.value.links;

    var metaInfo = {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: routeMeta.value.title || "",
      // all titles will be injected into this template
      titleTemplate: undefined,
      htmlAttrs: { 
        // attributes within HTML tag
        lang : currentLanguageCode.value,
        prefix : "og: https://ogp.me/ns#",
        ...configMeta.value.htmlAttrs
      },
      headAttrs: {
        // attributes within HEAD tag
      },
      bodyAttrs: {
        class : bodyClasses.join(" ")
      },
      meta : [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' },
        ...faviconMeta,
        ...configMeta.value.meta
      ],
      link : [
        ...hreflangLinks.value,
        ...faviconLinks,
        ...configMeta.value.links
      ],
      style: []
    }
    if(stylesMeta.value && stylesMeta.value.length > 0) {
      (metaInfo.link as Array<any>).push(...stylesMeta.value.map((style:string) => {
        return { rel: 'stylesheet', type : "text/css" , href : style}
      }))
    }
    if(appMeta.value && appMeta.value.meta) {
      (metaInfo.meta as Array<any>).push(...appMeta.value.meta);
    }
    if(appMeta.value && appMeta.value.link) {
      (metaInfo.link as Array<any>).push(...appMeta.value.link);
    }
    if(currentStyle.value && currentStyle.value != "") {
      (metaInfo.style as Array<any>).push({ cssText: currentStyle.value, type: 'text/css' });
    }
    if(routeMeta.value.description) {
      (metaInfo.meta as Array<any>).push({ vmid: 'description', name : "description" , content : routeMeta.value.description});
    }
    if(routeMeta.value.socialMedias) {
      /*

      <!--  Essential META Tags -->
      <meta property="og:title" content="European Travel Destinations">
      <meta property="og:description" content="Offering tour packages for individuals or groups.">
      <meta property="og:image" content="http://euro-travel-example.com/thumbnail.jpg">
      <meta property="og:url" content="http://euro-travel-example.com/index.htm">
      <meta property="og:image:secure_url" content="https://euro-travel-example.com/thumbnail.jpg">
      <meta name="twitter:card" content="summary_large_image">

      <!--  Non-Essential, But Recommended -->
      <meta property="og:site_name" content="European Travel, Inc.">
      <meta name="twitter:image:alt" content="Alt text for image">
      <meta name="og:image:alt" content="Alt text for image">
      <meta property="og:type" content="website or article">

      */

      if(routeMeta.value.socialMedias.title) {
        (metaInfo.meta as Array<any>).push({ vmid: 'og:title', property : "og:title" , content : routeMeta.value.socialMedias.title});
      }
      if(routeMeta.value.socialMedias.description) {
        (metaInfo.meta as Array<any>).push({ vmid: 'og:description', property : "og:description" , content : routeMeta.value.socialMedias.description});
      }
      if(routeMeta.value.socialMedias.image) {
        (metaInfo.meta as Array<any>).push({ vmid: 'og:image', property : "og:image" , content : routeMeta.value.socialMedias.image.replace("https","http")});
        (metaInfo.meta as Array<any>).push({ vmid: 'og:image:secure_url', property : "og:image:secure_url" , content : routeMeta.value.socialMedias.image});
        //og:image:width
        //og:image:height
        (metaInfo.meta as Array<any>).push({ vmid: 'twitter:card', name : "twitter:card" , content : "summary_large_image"});
      }
      if(routeMeta.value.socialMedias.imageAlt) {
        (metaInfo.meta as Array<any>).push({ vmid: 'twitter:image:alt', name : "twitter:image:alt" , content : routeMeta.value.socialMedias.imageAlt});
        (metaInfo.meta as Array<any>).push({ vmid: 'og:image:alt', name : "og:image:alt" , content : routeMeta.value.socialMedias.imageAlt});
      }
      if(routeMeta.value.socialMedias.url) {
        (metaInfo.meta as Array<any>).push({ vmid: 'og:url', property : "og:url" , content : routeMeta.value.socialMedias.url});
      }
      if(routeMeta.value.socialMedias.siteName) {
        (metaInfo.meta as Array<any>).push({ vmid: 'og:site_name', property : "og:site_name" , content : routeMeta.value.socialMedias.siteName});
      }
      if(routeMeta.value.socialMedias.type) {
        (metaInfo.meta as Array<any>).push({ vmid: 'og:type', property : "og:type" , content : routeMeta.value.socialMedias.type});
      }
    }
    return metaInfo;
  })

  store.commit('meta/' + metaTypes.mutations.SET_PAGE_META_INFO, {
    metaInfo : metaInfo.value
  });

  // We watch if we have site ID in URL
  watch(
    metaInfo,
    (val:any, oldVal:any) => {
      store.commit('meta/' + metaTypes.mutations.SET_PAGE_META_INFO, {
        metaInfo : metaInfo.value
      });     
    },
    { deep: true }
  )

  return {
    metaInfo
  }
  
}