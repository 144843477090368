import { Ref, watch, computed, ref, reactive, defineProps, PropType, ComputedRef } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '@root/src/client/store/application';
import { authenticationTypes } from '@fwk-client/store/types';

import { useCmsAdmin } from "./useCmsAdmin";

import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { roles as cmsRoles } from '@igotweb-node-api/api/modules/cms/src/roles/index';

interface SiteAdminInput {
}

export function useSiteAdmin(props:SiteAdminInput, {emit}:any) { 
  const app = getApp();
  const store = useStore();

  const { callCmsAdmin } = useCmsAdmin(props, {emit});
  const hasUserRole = store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

  var isListLoading:Ref<boolean> = ref(false);

  const getListSites = async (input?:any) => {
    var response = await callCmsAdmin('/sites/list', input);
    return response.sites ? response.sites : [];
  }
  
  const updateListSites = async () => {

    isListLoading.value = true;
    try {
      var sites = await getListSites();
      store.commit('application/'+applicationTypes.mutations.SET_USER_SITES, sites);
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  const getSiteFromID = (siteID:string) => {
    var site = sites.value.filter((site:any) => {
      return site.site._id == siteID
    })
    if(site.length == 1) { return site[0].site; }
    return null;
  }

  const getSiteFromShopID = (shopID:string) => {
    var site = sites.value.filter((site:any) => {
      return site.site.shop && site.site.shop._id == shopID
    })
    if(site.length == 1) { return site[0].site; }
    return null;
  }

  const getSitesFromAgencyID = (agencyID:string) => {
   return sites.value.filter((site:any) => {
      return site.site.agencies && site.site.agencies.map((agency:any) => { return agency._id; }).indexOf(agencyID) > -1
    }).map((site:any) => {
      return site.site;
    })
  }

  /**
   * selectSite
   * This method update the selected site by loading all associated details
   * @param siteID 
   */
  const selectSite = async (siteID:string) => {

    // We need to load the site and update the store
    try {
      var response = await callCmsAdmin('/site/'+siteID);
      store.commit('application/'+applicationTypes.mutations.SET_USER_SELECTED_SITE, response.site ? response.site : undefined);
    }
    catch(error:any) {
      console.log(error);
    }
  }

  const sites = computed(() => {
    return store.getters['application/'+applicationTypes.getters.GET_USER_SITES];
  })

  const selectedSite = computed(() => {
    return store.getters['application/'+applicationTypes.getters.GET_USER_SELECTED_SITE];
  })

  const selectedSiteTopLevelDomain = computed(() => {
    return selectedSite.value.site.topLevelDomain;
  })

  const siteValidationLink = computed(() => {
    return getSiteValidationLink(selectedSite.value.site);
  });

  const getSiteValidationLink = (site:any) => {
    if(site && site.topLevelDomain) {
      return "https://demo:demo@www-validation." + site.topLevelDomain;
    }
    return undefined;
  }

  const listRoutes:ComputedRef<any[]> = computed(() => {
    return (selectedSite && selectedSite.value.site.content && selectedSite.value.site.content.routes) ? selectedSite.value.site.content.routes : [];
  });

  const isSiteUpdateAllowed = (siteAuthRoles:string[]) => {
    return siteAuthRoles.indexOf(cmsRoles.site_admin) > -1
  }

  const isSiteCreateAllowed = () => {
    return hasUserRole(apiRoles.superadmin);
  }

  const isSiteRemoveAllowed = (siteAuthRoles:string[]) => {
    return siteAuthRoles.indexOf(cmsRoles.site_admin) > -1
  }

  const siteForm:any = reactive({});

  const updateSiteFormForUpdate = (site:any) => {
    siteForm.siteID = (site && site._id) ? site._id : undefined,
    siteForm.company = (site && site.company) ? site.company : null,
    siteForm.name = (site && site.name) ? site.name : '',
    siteForm.code = (site && site.code) ? site.code : '',
    siteForm.domains = (site && site.domains) ? site.domains : [],
    siteForm.isActivated = (site && site.isActivated) ? site.isActivated : false,
    siteForm.configuration = (site && site.configuration) ? JSON.stringify(site.configuration, null, 2) : "",
    siteForm.shop = (site && site.shop) ? site.shop : null,
    siteForm.feeds = (site && site.feeds) ? site.feeds : null,
    siteForm.agencies = (site && site.agencies) ? site.agencies : null
  }

  const siteAdminConfigurationForm:any = reactive({});

  const updateSiteAdminConfigurationFormForUpdate = (site:any) => {
    var adminConfiguration = site.adminConfiguration ? JSON.parse(JSON.stringify(site.adminConfiguration)) : {};
    siteForm.siteID = (site && site._id) ? site._id : undefined,
    siteAdminConfigurationForm.allowedComponents = adminConfiguration.allowedComponents
    siteAdminConfigurationForm.routes = adminConfiguration.routes
  }

  const createSite = async () => {

    var configuration = null;
    try {
      if(siteForm.configuration != "") {
        configuration = JSON.parse(siteForm.configuration); 
      }
    }
    catch(error:any) {
      configuration = undefined;
    }

    var result:any = {
      created: false
    }

    var input = {
      "companyID" : siteForm.company!._id,
      "code" : siteForm.code,
      "name" : siteForm.name,
      "domains" : siteForm.domains,
      "isActivated" : siteForm.isActivated,
      "siteID" : siteForm.siteID,
      "configuration" : configuration,
      "shopID" : siteForm.shop ? siteForm.shop._id : null,
      "feedIDs" : siteForm.feeds ? siteForm.feeds.map((feed:any) => feed._id) : [],
      "agencyIDs" : siteForm.agencies ? siteForm.agencies.map((agency:any) => agency._id) : []
    }

    try {
      var response = await callCmsAdmin('/sites/create', input);
      if(response.created) {  
        // We update the list of feeds
        updateListSites();

        result.created = true;
        result.site = response.site;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateSite = async () => {

    var configuration = null;
    try {
      if(siteForm.configuration != "") {
        configuration = JSON.parse(siteForm.configuration); 
      }
    }
    catch(error:any) {
      configuration = undefined;
    }

    var result:any = {
      updated: false
    }

    var input = {
      "companyID" : siteForm.company!._id,
      "code" : siteForm.code,
      "name" : siteForm.name,
      "domains" : siteForm.domains,
      "isActivated" : siteForm.isActivated,
      "siteID" : siteForm.siteID,
      "configuration" : configuration,
      "shopID" : siteForm.shop ? siteForm.shop._id : null,
      "feedIDs" : siteForm.feeds ? siteForm.feeds.map((feed:any) => feed._id) : [],
      "agencyIDs" : siteForm.agencies ? siteForm.agencies.map((agency:any) => agency._id) : []
    }

    try {
      var response = await callCmsAdmin('/site/'+siteForm.siteID+'/update', input);
      if(response.updated) {  
        // We update the list of feeds
        updateListSites();

        result.updated = true;
        result.site = response.site;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const removeSite = async (siteID:string) => {

    isListLoading.value = true;

    try {
      var response = await callCmsAdmin('/site/'+siteID+'/remove');
      if(response.removed) {
        updateListSites();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  const updateSiteAdminConfiguration = async () => {

    var result:any = {
      updated: false
    }

    var input:any = {
      ...siteAdminConfigurationForm
    }

    try {
      var response = await callCmsAdmin('/site/'+siteForm.siteID+'/admin-configuration/update', input);
      if(response.updated) {  
        // We update the list of sites
        updateListSites();

        result.updated = true;
        result.site = response.site;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  // We watch if we have site ID in URL
  watch(
    () => store.state.route.params.siteID,
    (val:any, oldVal:any) => {
      /*
        LOAD SITE
      */       
    },
    { deep: true }
  )

  return {
    sites,
    getListSites,
    getSiteFromID,
    getSiteFromShopID,
    getSitesFromAgencyID,
    getSiteValidationLink,
    selectedSite,
    selectSite,
    selectedSiteTopLevelDomain,
    siteValidationLink,
    listRoutes,
    isSiteUpdateAllowed,
    isSiteCreateAllowed,
    isSiteRemoveAllowed,
    siteForm,
    createSite,
    updateSite,
    removeSite,
    isListLoading,
    updateSiteFormForUpdate,
    siteAdminConfigurationForm,
    updateSiteAdminConfigurationFormForUpdate,
    updateSiteAdminConfiguration,
    cmsRoles
  }
  
}