<template>
  <div class="ibox agencies">
    <div class="ibox-title">
      <h2>{{ $t('hospitality.agency.list.title') }}</h2>
    </div>
    <div :class="'ibox-content p-md' + (listLoading ? ' sk-loading' : '')">

      <div v-if="listLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <p v-if="showNumberOfRows">Number of rows: {{numberOfRows}}</p>
    
      <b-table 
          outlined striped
          :items="agencies"
          :fields="listFields"
          ref="listItems">
        <template v-slot:cell(select)="row">
          <p-check class="p-default p-curve p-primary" color="success" :value="row.item.agency._id" v-model="listItemsSelected"></p-check>
        </template>
        <template v-slot:cell(options)="row">
          <span v-if="isAgencyUpdateAllowed">
            <a href="javascript:void(0)" @click="updateForm(row.item.agency)">{{$t('hospitality.agency.list.options.update')}}</a>
            /
          </span>
          <span v-if="isAgencyRemoveAllowed">
            <a href="javascript:void(0)" @click="confirmRemoveItem(row.item.agency)">{{$t('hospitality.agency.list.options.delete')}}</a>
            /
          </span>
          <a href="javascript:void(0)" @click="showAgencyApartmentsPage(row.item.agency)">{{$t('hospitality.agency.list.options.apartments')}}</a>
          /
          <a href="javascript:void(0)" @click="showDeploymentPage(row.item.agency)">{{$t('hospitality.agency.list.options.deployment')}}</a>
        </template>
      </b-table>

      <button v-if="isAgencyCreateAllowed" class="btn btn-primary" @click="showCreateItemModal()">{{$t('hospitality.agency.list.create-button')}}</button>

      <b-modal size="xl" ref="updateItemModal" :title="$t('hospitality.agency.update.title', {agencyName: itemToUpdate.name})" hide-footer lazy>
        <UpdateAgency :agency="itemToUpdate" v-on:agency-updated="onItemUpdated" />
      </b-modal>

      <b-modal size="xl" ref="createItemModal" title="Create new agency" hide-footer lazy>
        <CreateAgency v-on:agency-created="onItemCreated" />
      </b-modal>

      <b-modal ref="removeItemModal" 
          hide-header
          @ok="removeItem">
        {{$t('hospitality.agency.list.delete-confirmation', {agencyName: itemToRemove.name})}}
      </b-modal>
    </div>
  </div>
</template>

<style scoped>
  
</style>



<script lang="ts">
import { Ref, defineComponent, ComputedRef, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import UpdateAgency from './Update.vue';
import CreateAgency from './Create.vue';

import { languagesTypes } from '@fwk-client/store/types';

import { useAgencyAdmin } from '../../../../composables/useAgencyAdmin';


export default defineComponent({
  props: {
  },
  components: {
    UpdateAgency, CreateAgency
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const { agencies, selectAgency, isAgencyUpdateAllowed, isAgencyCreateAllowed, isAgencyRemoveAllowed, isListLoading, removeAgency } = useAgencyAdmin(props, context);

    const listItems:Ref<HTMLElement|null> = ref(null);
    const createItemModal:Ref<HTMLElement|null> = ref(null);
    const removeItemModal:Ref<HTMLElement|null> = ref(null);
    const updateItemModal:Ref<HTMLElement|null> = ref(null);

    const isMultiSelectAllowed:Ref<boolean> = ref(false);
    const showNumberOfRows:Ref<boolean> = ref(false);

    const numberOfRows:Ref<number> = computed(() => {
        return agencies.value.length
    })

    const itemToRemove:Ref<any> = ref({
    });
    const itemToUpdate:Ref<any> = ref({
    });

    
    const listLoading:Ref<boolean> = computed(() => {
      return isListLoading.value || removeLoading.value;
    })

    const listItemsSelected:Ref<any[]> = ref([]);

    const listFields = [
      {
        key: "select",
        label: "",
        class: (isMultiSelectAllowed.value ? "" : "hidden"),
      },
      {
        key: "agency.name",
        label: ""
      },
      {
        key: "options"
      }
    ];

    onMounted(() => {
      listFields[1].label = app.$t('hospitality.agency.list.headers.name') as string;
      listFields[2].label = app.$t('hospitality.agency.list.headers.options') as string;
    })

    const currentLanguageCode:ComputedRef<string> = computed(() => {
      return $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
    })

    const onItemCreated = () => {
      // @ts-ignore
      createItemModal.value.hide()
    }

    const onItemUpdated = (item:any) => {
      itemToUpdate.value = item;
    }

    const confirmRemoveItem = (item:any) => {
      itemToRemove.value = item;
      // @ts-ignore
      removeItemModal.value.show()
    }

    const updateForm = (item:any) => {
      itemToUpdate.value = item;
      // @ts-ignore
      updateItemModal.value.show()
    }

    const showCreateItemModal = () => {
      // @ts-ignore
      createItemModal.value.show()
    }

    const showAgencyApartmentsPage= (agency:any) => {
      // We first should select the agency
      selectAgency(agency._id).then(() => {
        // We redirect to the agency dedicated page
        $router.push("/"+currentLanguageCode.value+"/hospitality/apartments").catch((err:any) => {});
      })
    }

    const showDeploymentPage = (agency:any) => {
      // We first should select the agency
      selectAgency(agency._id).then(() => {
        // We redirect to the agency dedicated page
        $router.push("/"+currentLanguageCode.value+"/hospitality/deployment").catch((err:any) => {});
      })
    }

    const removeLoading:Ref<boolean> = ref(false);
    const removeItem = () => {

      removeLoading.value = true;
      removeAgency(itemToRemove.value._id).then((removed:boolean) => {
        // We reset the user to remove
        itemToRemove.value = {};

        removeLoading.value = false;
      })
    }
    return {
      listLoading,
      showNumberOfRows,
      numberOfRows,
      agencies,
      listItems,
      listFields,
      listItemsSelected,
      isAgencyUpdateAllowed,
      updateForm,
      isAgencyRemoveAllowed,
      confirmRemoveItem,
      showDeploymentPage,
      showAgencyApartmentsPage,
      isAgencyCreateAllowed,
      showCreateItemModal,
      updateItemModal,
      itemToUpdate,
      onItemUpdated,
      createItemModal,
      onItemCreated,
      removeItemModal,
      removeItem,
      itemToRemove
    }
  }
})
</script>