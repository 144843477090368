<template>
  
    <form role="form" @submit="onFormSubmit" name="create-agency">
        <TextField 
          ref="createName"
          v-bind:value.sync="agencyForm.name" 
          id="createName" 
          :label="$t('hospitality.agency.info.name')" 
          :placeholder="$t('hospitality.agency.info.name_placeholder')" 
          :labelAsColumn="true"
          :required="true"
        />
        <TextField 
          ref="createCode"
          v-bind:value.sync="agencyForm.code" 
          id="createCode" 
          :label="$t('hospitality.agency.info.code')" 
          :placeholder="$t('hospitality.agency.info.code_placeholder')" 
          :labelAsColumn="true"
          :required="true"
        />
        <SwitchCheck 
            ref="updateIsActivated"
            v-bind:value.sync="agencyForm.isActivated" 
            id="updateIsActivated" 
            :label="$t('hospitality.agency.info.isActivated')" 
            :labelAsColumn="true"
        />
        <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">Create</button>
    </form>

</template>


<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';

import { useAgencyAdmin } from '../../../../composables/useAgencyAdmin';

export default defineComponent({
  props: {
      site: {
        type: Object,
        required: false
      }
  },
  components: {
      TextField,
      SwitchCheck
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    
    const { agencyForm, createAgency } = useAgencyAdmin(props, context);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var button:HTMLButtonElement|null = document.querySelector( 'form[name=create-agency] button' );
      laddaSubmit = Ladda.create(button!);
    })

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      createAgency().then((result:any) => {
        if(result.created) {  
          context.emit('agency-created', result.agency);
        }
        laddaSubmit!.stop();
      })
    }

    return {
      onFormSubmit,
      agencyForm
    }
  }
})
</script>