<template>
  <validation-provider ref="validator" :name="id" mode="eager" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes}">
      <label :for="id" :class="{...labelCSSClass}">{{ inputLabel }} <small v-if="required">*</small></label>
      <div :class="{...fieldCSSClass}">
        <p-check :id="id" :class="{...inputCSSClass, 'p-switch':true, 'p-fill':true, 'required':required, 'form-control':false, ...classes}" color="success" v-model="input" :required="required">&nbsp;</p-check>
        <span :class="{...controlCSSClass}" v-if="errors.length > 0">{{ errors[0] }}</span>
      </div>
    </div>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Watch, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class SwitchCheck extends mixins<GenericInput<boolean>>(GenericInput) {

  input:any = this.value !== undefined ? this.value : false;

  get inputLabel() {
    return (this.label && this.label != "") ? this.label : "";
  }

  validate() {
    // @ts-ignore
    return this.$refs.validator.validate();
  }

  @Watch('value')
  onValueChange(val: any, oldVal: any) {
    this.input = val;
    // @ts-ignore
    this.$refs.validator.validate(val);
  }
  
}
</script>