<template>
  <section id="content" ref="agenciesContent">
    <PageTitle :title="$t('hospitality.admin.title')"></PageTitle>
    <div class="wrapper wrapper-content animated fadeInRight">

      <div class="row generate-success">
        <div class="col-lg-12">
          <ListAgencies></ListAgencies>
        </div>
      </div>

      <div class="row generate-success">
        <div class="col-lg-12">
          <CopyAgency></CopyAgency>
        </div>
      </div>

    </div>
  </section>
</template>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive, ComputedRef } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import { useGenericPage } from '@fwk-client/composables/useGenericPage';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';

import ListAgencies from '../../panels/admin/agencies/ListAgencies.vue';
import CopyAgency from '../../panels/admin/agencies/Copy.vue';

export default defineComponent({
  props: {
  },
  components: {
    PageTitle,
    ListAgencies,
    CopyAgency
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();

    const genericPage = useGenericPage(props, context);

    return {

    }
  }
});
</script>