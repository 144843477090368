<template>
  <div>
    <form role="form" @submit="onFormSubmit" name="update-agency">
      <div class="row">
        <div class="col-lg-12">
          <h3>{{$t('hospitality.agency.update.info')}}</h3>
        </div>
      </div>
      <TextField 
        ref="updateName"
        v-bind:value.sync="agencyForm.name" 
        id="updateName" 
        :label="$t('hospitality.agency.info.name')" 
        :placeholder="$t('hospitality.agency.info.name_placeholder')" 
        :labelAsColumn="true"
        :required="true"
      />
      <TextField 
        ref="updateCode"
        v-bind:value.sync="agencyForm.code" 
        id="updateCode" 
        :label="$t('hospitality.agency.info.code')" 
        :placeholder="$t('hospitality.agency.info.code_placeholder')" 
        :labelAsColumn="true"
        :required="true"
      />
      <SwitchCheck 
          ref="updateIsActivated"
          v-bind:value.sync="agencyForm.isActivated" 
          id="updateIsActivated" 
          :label="$t('hospitality.agency.info.isActivated')" 
          :labelAsColumn="true"
        />
      <button class="btn btn-primary ladda-button update" data-style="zoom-in" type="submit">{{$t('hospitality.agency.update.button')}}</button>
    </form>

  </div>

</template>

<style>

</style>

<script lang="ts">
import { toRefs, Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import * as Ladda from 'ladda';
import TextField from '@fwk-client/components/panels/input/TextField.vue';
import SwitchCheck from '@fwk-client/components/panels/input/SwitchCheck.vue';

import { useAgencyAdmin } from '../../../../composables/useAgencyAdmin';

export default defineComponent({
  props: {
      agency: {
        type: Object,
        required: true
      }
  },
  components: {
      TextField, SwitchCheck
  },
  setup(props, context) {
    const app = getApp();
    const $router = useRouter();
    const $store = useStore();
    
    const { agencies, updateAgency, updateAgencyFormForUpdate, agencyForm } = useAgencyAdmin(props, context);

    const { agency } = toRefs(props);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    onMounted(() => {
      var submitButton:HTMLButtonElement|null = document.querySelector( 'form[name=update-agency] button.update' );
      laddaSubmit = Ladda.create(submitButton!);
    })

    updateAgencyFormForUpdate(agency.value);

    const onFormSubmit = (evt:Event) => {
      // We prevent submit of the page
      evt.preventDefault();

      laddaSubmit!.start();
      updateAgency().then((result:any) => {
        if(result.updated) {
          context.emit('agency-updated', result.agency);
        }
        laddaSubmit!.stop();
      })
    }

    // We watch if we have site ID in URL
    watch(
      agency,
      (val:any, oldVal:any) => {
        updateAgencyFormForUpdate(agency.value);
      },
      { deep: true }
    )

    return {
      onFormSubmit,
      agencyForm
    }
  }
})
</script>