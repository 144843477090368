import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import * as api from '@fwk-client/utils/api';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '@root/src/client/store/application';
import { types as hospitalityTypes } from '@root/src/client/modules/hospitality/store';
import { authenticationTypes } from '@fwk-client/store/types';

import { roles as apiRoles } from '@igotweb/core-api/src/roles';
import { roles as hospitalityRoles } from '@igotweb-node-api/api/modules/hospitality/src/roles/index';

import { useSiteAdmin } from '../../cms/composables/useSiteAdmin';


interface AgencyAdminInput {
}

export function useAgencyAdmin(props:AgencyAdminInput, context:any) { 
  const app = getApp();
  const store = useStore();

  const { getSitesFromAgencyID } = useSiteAdmin(props, context);

  const hasUserRole = store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

  var isListLoading:Ref<boolean> = ref(false);
  var list:Ref<any[]> = ref([]);

  const updateInputWithTargetDB = (input:any) => {
    // We check if we have a target DB for the content
    var targetDB = store.getters['application/'+applicationTypes.getters.GET_ADMIN_TARGET_DB];
    if(targetDB) {
      input.targetDB = targetDB;
    }
  }

  const callAgencyAdmin = async (path:string, input?:any, formData?:FormData) => {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: app
    }
    if(!input) { input = {}; }
    updateInputWithTargetDB(input);
    if(formData) {
      return api.postAPIFormData('/api/admin/hospitality' + path, formData, options, input)
    }
    return api.postAPI('/api/admin/hospitality' + path, input, options);
  }

  const updateListAgencies = async () => {

    isListLoading.value = true;
    try {
      var response = await callAgencyAdmin('/agencies/list');
      store.commit('hospitality/'+hospitalityTypes.mutations.SET_USER_AGENCIES, response.agencies ? response.agencies : []);
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  const getAgencyFromID = (agencyID:string) => {
    var agency = agencies.value.filter((agency:any) => {
      return agency.agency._id == agencyID
    })
    if(agency.length == 1) { return agency[0].agency; }
    return null;
  }

  /**
   * selectAgency
   * This method update the selected agency by loading all associated details
   * @param agencyID 
   */
  const selectAgency = async (agencyID:string) => {

    // We need to load the site and update the store
    try {
      var response = await callAgencyAdmin('/agency/'+agencyID);
      store.commit('hospitality/'+hospitalityTypes.mutations.SET_USER_SELECTED_AGENCY, response.agency ? response.agency : undefined);
    }
    catch(error:any) {
      console.log(error);
    }
  }

  const agencies = computed(() => {
    return store.getters['hospitality/'+hospitalityTypes.getters.GET_USER_AGENCIES];
  })

  const selectedAgency = computed(() => {
    return store.getters['hospitality/'+hospitalityTypes.getters.GET_USER_SELECTED_AGENCY];
  })

  const selectedSite = computed(() => {
    var sites = getSitesFromAgencyID(selectedAgency.value.agency._id);
    if(sites.length > 0) {
      return sites[0];
    }
    return null;
  })

  const isAgencyUpdateAllowed = (agencyAuthRoles:string[]) => {
    return agencyAuthRoles.indexOf(hospitalityRoles.hospitality_admin) > -1
  }

  const isAgencyCreateAllowed = () => {
    return hasUserRole(apiRoles.superadmin);
  }

  const isAgencyRemoveAllowed = (agencyAuthRoles:string[]) => {
    return agencyAuthRoles.indexOf(hospitalityRoles.hospitality_admin) > -1
  }

  const agencyForm:any = reactive({
    code: '',
    name: '',
    isActivated: true
  });

  const updateAgencyFormForUpdate = (agency:any) => {
    agencyForm.agencyID = (agency && agency._id) ? agency._id : undefined,
    agencyForm.name =  agency.name,
    agencyForm.isActivated = agency.isActivated,
    agencyForm.code = agency.code
  }

  const agencyConfigurationForm:any = reactive({
    isStoreCartActivated:undefined,
    bookingNotificationEMail:undefined,
    notificationLanguageCode:undefined,
    bookingValidationNotificationDefaultMessage:undefined,
    bookingCancelationNotificationDefaultReason:undefined,
  });

  const updateAgencyConfigurationFormForUpdate = (agency:any) => {
    let configuration = agency.configuration ? JSON.parse(JSON.stringify(agency.configuration)) : {};
    agencyForm.agencyID = (agency && agency._id) ? agency._id : undefined,
    agencyConfigurationForm.isStoreCartActivated = configuration.isStoreCartActivated;
    agencyConfigurationForm.bookingNotificationEMail = configuration.bookingNotificationEMail ? configuration.bookingNotificationEMail.email : undefined;
    agencyConfigurationForm.notificationLanguageCode = configuration.notificationLanguageCode;
    agencyConfigurationForm.bookingValidationNotificationDefaultMessage = configuration.bookingValidationNotificationDefaultMessage;
    agencyConfigurationForm.bookingCancelationNotificationDefaultReason = configuration.bookingCancelationNotificationDefaultReason;
  }


  const createAgency = async () => {

    var result:any = {
      created: false
    }

    var input = {
      ...agencyForm
    }

    try {
      var response = await callAgencyAdmin('/agencies/create', input);
      if(response.created) {  
        // We update the fields with resulting agency
        updateAgencyFormForUpdate(response.agency)

        // We update the list of agencies
        updateListAgencies();

        result.created = true;
        result.agency = response.agency;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateAgency = async () => {

    var result:any = {
      updated: false
    }

    var input:any = {
      ...agencyForm
    }

    try {
      var response = await callAgencyAdmin('/agency/'+agencyForm.agencyID+'/update', input);
      if(response.updated) {  
        // We update the fields with resulting agency
        updateAgencyFormForUpdate(response.agency)

        // We update the list of agencies
        updateListAgencies();

        result.updated = true;
        result.agency = response.agency;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const updateAgencyConfiguration = async () => {

    var result:any = {
      updated: false
    }

    var input:any = {
      ...agencyConfigurationForm
    }

    try {
      var response = await callAgencyAdmin('/agency/'+agencyForm.agencyID+'/configuration/update', input);
      if(response.updated) {  
        // We update the fields with resulting agency
        updateAgencyFormForUpdate(response.agency)
        // We update the fields with resulting agency
        updateAgencyConfigurationFormForUpdate(response.agency)

        // We update the list of agencies
        updateListAgencies();

        result.updated = true;
        result.agency = response.agency;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }

  const publishAgencyConfiguration = async () => {

    var result:any = {
      published: false
    }

    try {
      var response = await callAgencyAdmin('/agency/'+selectedAgency.value.agency._id+'/configuration/publish');
      // We check the additional fields
      if(response.published) {
        result.published = true;
        result.agency = response.agency;
        store.commit('hospitality/'+hospitalityTypes.mutations.SET_USER_SELECTED_AGENCY, response.agency ? response.agency : undefined);
      }
    }
    catch(error:any) {
      console.log(error);
    }
  }

  const removeAgency = async (agencyID:string) => {

    isListLoading.value = true;

    try {
      var response = await callAgencyAdmin('/agency/'+agencyID+'/remove');
      if(response.removed) {
        updateListAgencies();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  // We watch if we have site ID in URL
  watch(
    () => store.state.route.params.siteID,
    (val:any, oldVal:any) => {
      /*
      LOAD SITE
    */       
    },
    { deep: true }
  )

  return {
    callAgencyAdmin,
    isListLoading,
    agencies,
    getAgencyFromID,
    selectedAgency,
    selectedSite,
    selectAgency,
    agencyForm,
    agencyConfigurationForm,
    createAgency,
    updateAgency,
    updateAgencyConfiguration,
    removeAgency,
    isAgencyUpdateAllowed,
    isAgencyCreateAllowed,
    isAgencyRemoveAllowed,
    updateAgencyFormForUpdate,
    updateAgencyConfigurationFormForUpdate,
    publishAgencyConfiguration,
    hospitalityRoles
  }
  
}