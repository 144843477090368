<template>
    <div class="row wrapper border-bottom white-bg page-heading">
        <div class="col-lg-10">
            <h2>{{ title }}</h2>
            <ol v-if="breadcrumbs" class="breadcrumb">
                <li v-for="(breadcrumb, index) in breadcrumbs" :key="index" :class="'breadcrumb-item' + (breadcrumb.isActive ? ' active' : '')">
                    <a :href="breadcrumb.href ? breadcrumb.href : 'javascript:void(0);'">{{ breadcrumb.label }}</a>
                </li>
            </ol>
        </div>
        <div class="col-lg-2"></div>
    </div>
</template>

<script lang="ts">
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

export default defineComponent({
    props: {
        title : {
            type: String,
            required: true
        },
        /**
         * Array of breadcrumb which contain:
         * - isActive: boolean true if link should be active.
         * - label: the label to be displayed.
         */
        breadcrumbs: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    components: {
    },
    setup(props, context) {
      

      return { 
        
      }
    }
})
</script>